<div class="status-icon-container">
  <div [ngClass]="status" class="d-flex align-items-center justify-content-between">
    <div></div>
    <div class="d-flex">
      <i [class]="'icon xs i-'+status+' bw'" [smTooltip]="EXPERIMENTS_STATUS_LABELS[status]"></i>
      <div class="label" *ngIf="showLabel">
        {{'  ' + (EXPERIMENTS_STATUS_LABELS[status] ? EXPERIMENTS_STATUS_LABELS[status] : '') | uppercase}}
      </div>
      <span class="view-only d-inline" *ngIf="viewOnly === true">  (view only) </span>
    </div>
    <div class="right-buttons d-flex align-items-center">
      <div *ngIf="showMaximize" (click)="maximizedClicked.emit()"
        smTooltip="View Full Screen" matTooltipPosition="left" class="d-flex fullscreen">
        <i class="al-icon al-ico-info-max pointer"></i>
      </div>
      <div (click)="closeInfoClicked.emit()" class="d-flex align-items-center">
        <i class="al-icon al-ico-dialog-x pointer"></i>
      </div>
    </div>
  </div>
</div>
