/**
 * login
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 2.12
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/member-ordering */

import {HTTP} from '../../app.constants';
import {SmApiRequestsService} from "./api-requests.service";

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { LoginAddInvitesRequest } from '../model/login/loginAddInvitesRequest';
import { LoginAddInvitesResponse } from '../model/login/loginAddInvitesResponse';
import { LoginGetInviteInfoRequest } from '../model/login/loginGetInviteInfoRequest';
import { LoginGetInviteInfoResponse } from '../model/login/loginGetInviteInfoResponse';
import { LoginGetSettingsResponse } from '../model/login/loginGetSettingsResponse';
import { LoginGetWhitelistRequest } from '../model/login/loginGetWhitelistRequest';
import { LoginGetWhitelistResponse } from '../model/login/loginGetWhitelistResponse';
import { LoginJoinCompanyRequest } from '../model/login/loginJoinCompanyRequest';
import { LoginJoinCompanyResponse } from '../model/login/loginJoinCompanyResponse';
import { LoginLeaveCompanyRequest } from '../model/login/loginLeaveCompanyRequest';
import { LoginLeaveCompanyResponse } from '../model/login/loginLeaveCompanyResponse';
import { LoginLogoutRequest } from '../model/login/loginLogoutRequest';
import { LoginLogoutResponse } from '../model/login/loginLogoutResponse';
import { LoginRemoveInvitesRequest } from '../model/login/loginRemoveInvitesRequest';
import { LoginRemoveInvitesResponse } from '../model/login/loginRemoveInvitesResponse';
import { LoginSetDomainsRequest } from '../model/login/loginSetDomainsRequest';
import { LoginSignupUserRequest } from '../model/login/loginSignupUserRequest';
import { LoginSignupUserResponse } from '../model/login/loginSignupUserResponse';
import { LoginSsoAuthorizeRequest } from '../model/login/loginSsoAuthorizeRequest';
import { LoginSsoAuthorizeResponse } from '../model/login/loginSsoAuthorizeResponse';
import { LoginSsoCallbackRequest } from '../model/login/loginSsoCallbackRequest';
import { LoginSsoCallbackResponse } from '../model/login/loginSsoCallbackResponse';
import { LoginSupportedModesRequest } from '../model/login/loginSupportedModesRequest';
import { LoginSupportedModesResponse } from '../model/login/loginSupportedModesResponse';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import {LoginRemoveWhitelistEntriesResponse} from "../model/login/loginRemoveWhitelistEntriesResponse";
import {LoginRemoveWhitelistEntriesRequest} from "../model/login/loginRemoveWhitelistEntriesRequest";
import {LoginAddWhitelistEntriesResponse} from "../model/login/loginAddWhitelistEntriesResponse";
import {LoginAddWhitelistEntriesRequest} from "../model/login/loginAddWhitelistEntriesRequest";


@Injectable()
export class ApiLoginService {

    protected basePath = HTTP.API_BASE_URL;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected apiRequest: SmApiRequestsService, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     *
     * Adds new login whitelist entries.
     * @param request request body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public loginAddInvites(request: LoginAddInvitesRequest, options?: any, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling loginAddInvites.');
        }

        let headers = this.defaultHeaders;
        if (options && options.async_enable) {
            headers = headers.set(this.configuration.asyncHeader, '1');
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

	return this.apiRequest.post<LoginAddInvitesResponse>(`${this.basePath}/login.add_invites`,
            request,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     * Get invite info by invite ID
     * @param request request body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public loginGetInviteInfo(request: LoginGetInviteInfoRequest, options?: any, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling loginGetInviteInfo.');
        }

        let headers = this.defaultHeaders;
        if (options && options.async_enable) {
            headers = headers.set(this.configuration.asyncHeader, '1');
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

	return this.apiRequest.post<LoginGetInviteInfoResponse>(`${this.basePath}/login.get_invite_info`,
            request,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     * Get the company\&#39;s login settings, including auto-login domains and user invites
     * @param request request body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public loginGetSettings(request: object, options?: any, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling loginGetSettings.');
        }

        let headers = this.defaultHeaders;
        if (options && options.async_enable) {
            headers = headers.set(this.configuration.asyncHeader, '1');
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

	return this.apiRequest.post<LoginGetSettingsResponse>(`${this.basePath}/login.get_settings`,
            request,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     * Get whitelist entries (optionally filtered). Reserved for internal use.
     * @param request request body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public loginGetWhitelist(request: LoginGetWhitelistRequest, options?: any, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling loginGetWhitelist.');
        }

        let headers = this.defaultHeaders;
        if (options && options.async_enable) {
            headers = headers.set(this.configuration.asyncHeader, '1');
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

	return this.apiRequest.post<LoginGetWhitelistResponse>(`${this.basePath}/login.get_whitelist`,
            request,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     * Get whitelist entries (optionally filtered). Reserved for internal use.
     * @param request request body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public loginRemoveWhitelistEntries(request: LoginRemoveWhitelistEntriesRequest, options?: any, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling loginGetWhitelist.');
        }

        let headers = this.defaultHeaders;
        if (options && options.async_enable) {
            headers = headers.set(this.configuration.asyncHeader, '1');
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

	return this.apiRequest.post<LoginRemoveWhitelistEntriesResponse>(`${this.basePath}/login.remove_whitelist_entries`,
            request,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    /**
     *
     * Get whitelist entries (optionally filtered). Reserved for internal use.
     * @param request request body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public loginAddWhitelistEntries(request: LoginAddWhitelistEntriesRequest, options?: any, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling loginGetWhitelist.');
        }

        let headers = this.defaultHeaders;
        if (options && options.async_enable) {
            headers = headers.set(this.configuration.asyncHeader, '1');
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

	return this.apiRequest.post<LoginAddWhitelistEntriesResponse>(`${this.basePath}/login.add_whitelist_entries`,
            request,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     * Join a tenant company by invite and regenerate a token
     * @param request request body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public loginJoinCompany(request: LoginJoinCompanyRequest, options?: any, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling loginJoinCompany.');
        }

        let headers = this.defaultHeaders;
        if (options && options.async_enable) {
            headers = headers.set(this.configuration.asyncHeader, '1');
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

	return this.apiRequest.post<LoginJoinCompanyResponse>(`${this.basePath}/login.join_company`,
            request,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     * Leave a tenant company and regenerate a token on the user own company
     * @param request request body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public loginLeaveCompany(request: LoginLeaveCompanyRequest, options?: any, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling loginLeaveCompany.');
        }

        let headers = this.defaultHeaders;
        if (options && options.async_enable) {
            headers = headers.set(this.configuration.asyncHeader, '1');
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

	return this.apiRequest.post<LoginLeaveCompanyResponse>(`${this.basePath}/login.leave_company`,
            request,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     * Logout from SSO
     * @param request request body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public loginLogout(request: LoginLogoutRequest, options?: any, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling loginLogout.');
        }

        let headers = this.defaultHeaders;
        if (options && options.async_enable) {
            headers = headers.set(this.configuration.asyncHeader, '1');
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

	return this.apiRequest.post<LoginLogoutResponse>(`${this.basePath}/login.logout`,
            request,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     * Remove existing login invites. Only possible for invites that have not already   been accepted.
     * @param request request body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public loginRemoveInvites(request: LoginRemoveInvitesRequest, options?: any, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling loginRemoveInvites.');
        }

        let headers = this.defaultHeaders;
        if (options && options.async_enable) {
            headers = headers.set(this.configuration.asyncHeader, '1');
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

	return this.apiRequest.post<LoginRemoveInvitesResponse>(`${this.basePath}/login.remove_invites`,
            request,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     * Sets the auto-login domains for this company.
     * @param request request body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public loginSetDomains(request: LoginSetDomainsRequest, options?: any, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling loginSetDomains.');
        }

        let headers = this.defaultHeaders;
        if (options && options.async_enable) {
            headers = headers.set(this.configuration.asyncHeader, '1');
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

	return this.apiRequest.post<object>(`${this.basePath}/login.set_domains`,
            request,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     * Creates a new user and logges it in
     * @param request request body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public loginSignupUser(request: LoginSignupUserRequest, options?: any, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling loginSignupUser.');
        }

        let headers = this.defaultHeaders;
        if (options && options.async_enable) {
            headers = headers.set(this.configuration.asyncHeader, '1');
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

	return this.apiRequest.post<LoginSignupUserResponse>(`${this.basePath}/login.signup_user`,
            request,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     * Return provider redirect URL.
     * @param request request body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public loginSsoAuthorize(request: LoginSsoAuthorizeRequest, options?: any, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling loginSsoAuthorize.');
        }

        let headers = this.defaultHeaders;
        if (options && options.async_enable) {
            headers = headers.set(this.configuration.asyncHeader, '1');
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

	return this.apiRequest.post<LoginSsoAuthorizeResponse>(`${this.basePath}/login.sso_authorize`,
            request,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     * Handle callback received from provider. Returns user ID and embeds token on the   session.
     * @param request request body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public loginSsoCallback(request: LoginSsoCallbackRequest, options?: any, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling loginSsoCallback.');
        }

        let headers = this.defaultHeaders;
        if (options && options.async_enable) {
            headers = headers.set(this.configuration.asyncHeader, '1');
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

	return this.apiRequest.post<LoginSsoCallbackResponse>(`${this.basePath}/login.sso_callback`,
            request,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     * Handle post callback received from provider. Embeds token on the session and   redirects to the client callback.
     * @param request request body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public loginSsoCallbackMicrosoftAd(request: object, options?: any, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling loginSsoCallbackMicrosoftAd.');
        }

        let headers = this.defaultHeaders;
        if (options && options.async_enable) {
            headers = headers.set(this.configuration.asyncHeader, '1');
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

	return this.apiRequest.post<object>(`${this.basePath}/login.sso_callback_microsoft_ad`,
            request,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     * Return supported login modes.
     * @param request request body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public loginSupportedModes(request: LoginSupportedModesRequest, options?: any, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling loginSupportedModes.');
        }

        let headers = this.defaultHeaders;
        if (options && options.async_enable) {
            headers = headers.set(this.configuration.asyncHeader, '1');
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }
	return this.apiRequest.post<LoginSupportedModesResponse>(`${this.basePath}/login.supported_modes`,
            request,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
