<span
  class=content
  [style.backgroundColor]="colors?.background"
  [style.color]="colors?.foreground"
  [class.add-button]="add.observers.length > 0"
  (click)="add.emit($event)"
  [smTooltip]="tooltip ? caption : undefined"
>
  <i class="fas fa-plus" *ngIf="add.observers.length > 0"></i>
  {{caption}}
</span>
<i class="fas fa-xs fa-times remove"
   *ngIf="remove.observers.length > 0 && !disabledRemove"
   [style.color]="colors?.foreground"
   (click)="onRemoveClicked(caption)">
</i>
<mat-spinner *ngIf="disabledRemove" class="icon sm white remove" [diameter]="17" [strokeWidth]="3"></mat-spinner>
<div class="arrow" [class.add-button]="add.observers.length > 0" [style.backgroundColor]="colors?.background"></div>
