<sm-compare-footer >
  <ng-container *ngFor="let footerItem of footerItems; trackBy: trackBy"   >
    <ng-container></ng-container>
    <ng-container *ngIf="!footerItem.isTag; else tags">
      <ng-container *ngIf="footerItem.state$ | async as footerState">
         <span
           *ngIf="!footerState.preventCurrentItem"
           matTooltipPosition="above"
           [ngClass]="footerItem.wrapperClass"
           [smTooltip]="footerItem.disable || footerState.disable
                                  ? footerItem.disableDescription || footerState.disableDescription
                                  : footerState.description   || footerItem.description"
         >
        <button class="compare-button-container"
                [ngClass]="footerItem.class || footerState.class"
                (click)="footerItem.emit && onFooterItemClick.emit({item: footerItem, emitValue: footerState.emitValue })"
                [class.disabled]="footerState.disable ? footerState.disable : null"
        >
          <i *ngIf="footerItem.icon" [class]="'sm al-icon ' + footerItem.icon + ' sm-md al-color blue-100'"></i>
          <i *ngIf="footerState.icon" [class]="'sm al-icon ' + footerState.icon + ' sm-md al-color blue-100'"></i>
          <div *ngIf="footerItem.title" class="title">{{footerItem.title}}</div>
          <div *ngIf="footerState.title" class="title">{{footerState.title}}</div>
        </button>
      </span>
      </ng-container>
    </ng-container>
    <ng-template #tags>
      <span class="tags-container"  *ngIf="footerItem.state$ | async as footerState"
            matTooltipPosition="above"
            [smTooltip]="footerItem.disable || footerState.disable
                                  ? footerItem.disableDescription || footerState.disableDescription
                                  : footerState.description   || footerItem.description"
      >
        <button mat-menu-item
                [matMenuTriggerFor]="tagMenu"
                [disabled]="footerState.disable"
                (menuOpened)="tagMenuOpened()"
                (menuClosed)="tagMenuClosed()"
        >
        <i [class]="'al-icon ' + ICONS.TAG + ' sm-md'"></i>
      </button>
      <mat-menu #tagMenu="matMenu">
        <sm-tags-menu
          #tagMenuContent
          class="light-theme"
          [tags]="footerItem.tags$ | async"
          [tagsFilterByProject]="footerItem.tagsFilterByProject$ | async"
          [projectTags]="footerItem.projectTags$ | async"
          [companyTags]="footerItem.companyTags$ | async"
          (tagSelected)="tagSelected.emit({tags: $event, emitValue: footerState.emitValue})">
        </sm-tags-menu>
      </mat-menu>
      </span>
    </ng-template>
  </ng-container>
</sm-compare-footer>
