<sm-survey *ngIf="!guestUser && showSurvey && (showSurvey$ | async) && !update?.active"
           (dismiss)="dismissSurvey()"></sm-survey>
<sm-update-notifier #update
                    [availableUpdates]="!hideUpdate && (updatesAvailable$ | async)"
                    [currentUser]="currentUser"
                    [dismissedVersion]="serverUpdatesService.lastDismissedVersion"
                    (versionDismissed)="versionDismissed($event)"
                    (notifierActive)="notifierActive($event)">
</sm-update-notifier>
<sm-color-picker-wrapper id="color-picker-outlet"></sm-color-picker-wrapper>
<sm-server-notification-dialog-container></sm-server-notification-dialog-container>
<sm-spinner></sm-spinner>
<sm-side-nav *ngIf="currentUser"></sm-side-nav>
<div class="app-container" [class.login-page]="!currentUser"
     [class.notifier-open]="update?.active || ((showSurvey$ | async) && showSurvey && !demo && !guestUser)">
  <sm-header *ngIf="currentUser" [isLogin]="isLoginContext"
             [isShareMode]="isSharedAndNotOwner$ | async"></sm-header>
  <router-outlet class="main-router"></router-outlet>
</div>
<notifier-container></notifier-container>
