<div class="header-container" [class.login]="isLogin">
  <div class="d-flex align-items-center">
    <sm-breadcrumbs
      class="spacer"
      [class.share-view]="isShareMode"
      [activeWorkspace]="activeWorkspace">
    </sm-breadcrumbs>
    <div class="ml-3 d-flex align-items-center" *ngIf="userFocus">
      <sm-show-only-user-work></sm-show-only-user-work>
    </div>
  </div>

  <div *ngIf="isDashboard && !isLogin" class="logo-full middle"
       [class.make-room-for-slogan]="environment.whiteLabelSlogan">
    <img [src]="environment?.branding?.logo">
    <div *ngIf="environment.whiteLabelSlogan" class="slogan">{{environment.whiteLabelSlogan}}</div>
  </div>
  <div *ngIf="isLogin && environment.whiteLabelLogo" class="logo-full">
    <img [src]="environment?.branding?.logo">
  </div>
  <div *ngIf="isLogin" class="spacer"></div>

  <div *ngIf="workspaces | async as workspaces" class="right-buttons">
    <sm-common-search [class.share-view]="isShareMode"></sm-common-search>

    <a class="pointer resources-trigger"
       menuTrigger="matMenuTrigger"
       [matMenuTriggerFor]="resourcesMenu"
       showDelay="0" matTooltipPosition="below">
      <i class="far al-ico-help-outlined"></i>
    </a>
    <a class="pointer menu-trigger"
       menuTrigger="matMenuTrigger"
       [matMenuTriggerFor]="profileMenu"
       showDelay="0" matTooltipPosition="below">
      <img class="avatar" *ngIf="(user | async).avatar; else iconAvatar" [src]="(user | async).avatar">
      <ng-template #iconAvatar>
        <div class="user-icon" [class.neon]="(url | async)?.endsWith('profile')">
          <i class="fa fa-user-alt fa-lg"></i>
        </div>
      </ng-template>
    </a>

    <mat-menu #profileMenu="matMenu" class="user-menu">
      <button mat-menu-item routerLink="/profile">Profile</button>
      <ng-container *ngIf="environment.communityServer">
        <button mat-menu-item (click)="openInviteModal()">Invite a User</button>
        <ng-container *ngIf="workspaces">
          <hr>
          <div class="section-title">SWITCH TO WORKSPACE</div>
          <button mat-menu-item
                  *ngFor="let workspace of workspaces; let first = first"
                  class="workspace-button"
                  (click)="switchWorkspace(workspace)">
            <i [class.fa-check-circle]="activeWorkspace.id === workspace.id" class="fas"></i>
            {{first ? 'My Workspace' : workspace.name}}
          </button>
          <hr>
        </ng-container>
      </ng-container>
      <button *ngIf="userManagement$ | async" mat-menu-item (click)="userManagement()">Access Controls</button>
      <button mat-menu-item (click)="logout()">Logout</button>
    </mat-menu>
    <mat-menu #resourcesMenu="matMenu" class="user-menu">
      <button mat-menu-item *ngIf="environment.slackLink">
        <a class="item d-block" target="_blank"
           [href]="environment.slackLink">
          <i class="fab fa-slack"></i>Community on Slack
        </a>
      </button>
      <button mat-menu-item>
        <a class="item d-block" [href]="environment.docsLink" target="_blank">
          <i class="far al-ico-documentation"></i>ClearML Documentation
        </a>
      </button>
      <button mat-menu-item>
        <a class="item d-block" href="https://www.youtube.com/c/ClearML/featured" target="_blank">
          <i class="fab fa-youtube"></i>ClearML on Youtube
        </a>
      </button>
      <button mat-menu-item (click)="openTip()">
        <i class="far al-ico-tips"></i>Pro Tips
      </button>
      <button mat-menu-item>
        <a class="item d-block" href="" (click)="openWelcome($event)">
          <i class="fa al-ico-setup"></i>ClearML Python Package setup
        </a>
      </button>
      <button mat-menu-item>
        <a class="item d-block" href="https://www.clear.ml/contact-us/" target="_blank">
          <i class="fas al-ico-email"></i>Contact Us
        </a>
      </button>
    </mat-menu>
  </div>
</div>
<ng-content></ng-content>
