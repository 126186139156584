<sm-dialog-template [header]="title">
  <textarea [(ngModel)]="textData"
            class="data-text"
            [disabled]="readOnly"
            [placeholder]="placeHolder? placeHolder:''"
            (keydown)="textChanged()">
  </textarea>
  <div>
    <small *ngIf="this.showErrors"
           class="text-danger">*Not a valid json.
    </small>
  </div>
  <div class="buttons">
    <button (click)="closeDialog(false)" class="btn btn-outline-neon yes-button">
      Cancel
    </button>
    <button (click)="closeDialog(!readOnly)" class="btn btn-neon">
      OK
    </button>
  </div>

</sm-dialog-template>
