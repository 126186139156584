<sm-dialog-template [displayX]="true"
                    [header]="queue? 'NO WORKERS ASSIGNED TO QUEUE': (step === 1 ? 'Welcome to ClearML' : 'GETTING STARTED')"
                    (xClicked)="closeDialog()" [closeOnX]="false"
                    [iconClass]="queue?'al-ico-queues': 'i-welcome-researcher'">

  <div *ngIf="step === 1; else configStep" class="welcome-content">
    <div class="body">
      <div class="info">
        <span class="position-relative">
          <i class="al-ico-card-example foreground"></i>
          <span class="background"></span>
        </span>
        <div class="mt-2">See the pre-loaded examples to quickly get familiar with ClearML’s various capabilities.</div>
      </div>
      <div class="info">
        <i class="al-ico-help-outlined"></i>
        <div class="mt-2">Browse "Pro Tips" in the Help menu to jump start your work flow.</div>
      </div>
      <div class="info">
        <i class="al-ico-documentation"></i>
        <div class="mt-2">Check out the <a target="_blank" [href]="docsLink">ClearML docs</a> for advanced information and in depth how-to’s.
        </div>
      </div>
    </div>
    <div class="separator"></div>
    <div class="d-flex justify-content-center">
      <button class="btn btn-neon" (click)="nextSteps($event)">GET STARTED</button>
    </div>
  </div>
  <ng-template #configStep>
    <div class="steps-content">
      <div class="text" *ngIf="queue">
        Tasks have been enqueued on the <b>{{queue?.name}}</b> queue, which is currently not serviced by any worker. They will remain in the 'pending' state until a ClearML worker services this queue.
      </div>
      <div *ngFor="let step of steps" class="step-container">
        <div class="step-header">{{step.header}}</div>
        <div class="step">{{step.title}}</div>
        <div class="code">
          <div class="content" #stepCode>{{step.code}}</div>
          <sm-copy-clipboard
            [hideBackground]="true"
            [label]="''"
            [copyIcon]="'far fa-lg fa-copy'"
            [clipboardText]="stepCode.innerHTML"></sm-copy-clipboard>
        </div>
        <div *ngIf="step.subNote" class="sub-note"><i class="mr-1 fas fa-info-circle info"></i>{{step.subNote}}</div>
      </div>
      <div class="step-container">
        <div class="step-header sub-note">Complete the clearml configuration information as prompted.</div>
        <div *ngIf="!accessKey ; else config" class="code empty-config">
          <div>
            <button class="mb-2 btn btn-neon" [disabled]="creatingCredentials" (click)="createCredentials()">CREATE NEW
              CREDENTIALS
            </button>
          </div>
        </div>
        <ng-template #config>
          <div class="code">
<div #content class="content"><span class="variable">api</span> {{ '{' }}<ng-container *ngIf="community && workspace.name">
  <span class="comment">{{'# ' + workspace.name}}</span></ng-container>
  <span class="variable">web_server</span><span class="operation">:</span> <span class="string">{{WEB_SERVER_URL}}</span>
  <span class="variable">api_server</span><span class="operation">:</span> <span class="string">{{API_BASE_URL}}</span>
<ng-container *ngIf="fileBaseUrl">  <span class="variable">files_server</span><span class="operation">:</span> <span class="string">{{fileBaseUrl}}</span>
</ng-container>  <span class="variable">credentials</span> {{ '{' }}
    <span class="string">"access_key"</span> <span class="operation">=</span> <span class="string">"{{accessKey}}"</span>
    <span class="string">"secret_key"</span> <span class="operation">=</span> <span class="string">"{{secretKey}}"</span>
  }
}</div>
            <sm-copy-clipboard
              [hideBackground]="true"
              [label]="''"
              [copyIcon]="'far fa-lg fa-copy'"
              [clipboardText]="content.textContent"></sm-copy-clipboard>
          </div>
        </ng-template>
        <div class="sub-note"><i class="mr-1 fas fa-info-circle info"></i>Manage your app credentials in the <a target="_blank" href="/profile">Profile page</a></div>
      </div>
      <div class="step-container" *ngIf="!queue">
        <div class="step">3. Integrate</div>
        <div class="step sub-note">Add the following lines to your code</div>
        <div class="code">
          <div #content class="content"><span class="variable">from</span> {{gettingStartedContext?.agentName || 'clearml'}} <span class="variable">import</span> Task
task <span class="operation">=</span> Task.<span class="variable">init</span>(project_name<span class="operation">=</span>"my project", task_name<span class="operation">=</span>"my task")</div>
          <sm-copy-clipboard
            [hideBackground]="true"
            [label]="''"
            [copyIcon]="'far fa-lg fa-copy'"
            [clipboardText]="content.textContent"></sm-copy-clipboard>
        </div>
      </div>
      <div *ngIf="queue" class="text">
        <sm-checkbox-control
          fieldName="orphanedQueue"
          [formData]="doNotShowAgain"
          (formDataChanged)="doNotShowThisAgain($event)"
          label="Don’t show again"></sm-checkbox-control>
      </div>
    </div>
  </ng-template>
</sm-dialog-template>
