<div class="refresh-wrapper d-flex flex-row-reverse align-items-center" [ngClass]="{'allow-autorefresh': allowAutoRefresh}">
  <span
    *ngIf="allowAutoRefresh && autoRefreshState; else pause"
    class="pl-1 al-icon al-ico-auto-refresh-play"
    (click)="refreshList.emit(false)"
  ><span class="path1"></span><span class="path2"></span></span>
  <ng-template #pause>
    <span
      class="pl-1 al-icon al-ico-auto-refresh-pause"
      (click)="refreshList.emit(false)"
    ><span class="path1"></span><span class="path2"></span>
    </span>
  </ng-template>
  <mat-checkbox [ngModel]="autoRefreshState" name="autoRefresh" (ngModelChange)="setAutoRefresh.emit($event)" class="autorefresh-checkbox">
    Auto Refresh
  </mat-checkbox>
</div>
