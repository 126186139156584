<div class="title">{{titleText}}</div>
<sm-search
  [value]="searchText"
  [minimumChars]="0"
  [debounceTime]="0"
  placeholder="Search parameters"
  (valueChanged)="searchQ($event)"
></sm-search>
<div class="actions">
  <div class="pointer" (click)="onClearSelection()">Clear Selection</div>
  <ng-content></ng-content>
</div>
<div class="list">
  <mat-expansion-panel *ngFor="let bigItem of itemsObjectList | keyvalue | sort:'key'; trackBy: trackByKeyFn"
                       [expanded]="searchText?.length > 0"
                       [hidden]="(bigItem.value | advancedFilter:searchText).length === 0">
    <mat-expansion-panel-header expandedHeight="40px" collapsedHeight="40px" (click)="toggleExpand(bigItem.key)">
      <div class="ellipsis item-key" [smTooltip]="RENAME_MAP[bigItem.key]" [showDelay]="500">{{RENAME_MAP[bigItem.key] || bigItem.key}}</div>
    </mat-expansion-panel-header>
    <div *ngFor="let item of bigItem.value | filter:searchText:'value'; trackBy: trackByValFn"
         class="item pointer"
         [class.disabled]="cb.disabled"
         [smTooltip]="!item.checked && selectionLimitReached? 'You can only select up to ' + limitSelection + ' parameters' : ''"
         customClass="parameter-tooltip"
         (click)="!cb.disabled && toggleParamToDisplay(bigItem.key + '.' + item.value, item.checked)">
      <mat-checkbox #cb
                    smClickStopPropagation
                    [ngModel]="item.checked"
                    [disabled]="selectionLimitReached && !item.checked"
                    (ngModelChange)="toggleParamToDisplay(bigItem.key + '.' + item.value, item.checked)"
      >
      </mat-checkbox>
      <label class="ellipsis">{{item.value}}</label>
    </div>
  </mat-expansion-panel>
</div>
