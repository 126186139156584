<div class="editable-div">
  <div [style.display]="active ? 'none': 'block'" >
    <div class="d-flex align-items-center" #template (click)="(!inlineDisabled) && inlineActivated()"
      ><ng-content></ng-content>
      <i *ngIf="editable && !inlineDisabled" class='p2 al-icon al-ico-edit sm-md pull-right editable-pen pointer'></i>
    </div>
  </div>
    <div *ngIf="editable" [style.display]=" ! active ? 'none': 'block'" tabindex="12">
      <form #form class="inline-edit-form" [class.multiline]="multiline">
        <input type="text" class="form-control inline-edit-input"
               *ngIf="!multiline"
               (blur)="inlineCanceled()"
               [(ngModel)]="inlineValue"
               name="inline"
               [pattern]="pattern"
               (keydown.tab)="form.checkValidity() && inlineSaved()"
               (keydown.escape)="inlineCanceled()"
               (keydown.enter)="form.checkValidity() && inlineSaved()"
               #inlineInput />

        <textarea class="form-control inline-edit-textarea"
                  *ngIf="multiline"
                  (blur)="inlineCanceled()"
                  [(ngModel)]="inlineValue"
                  name="inline"
                  [pattern]="pattern"
                  (keydown.tab)="inlineSaved()"
                  (keydown.escape)="inlineCanceled()"
                  [rows]="multiline?rows:1"
                  #inlineInput>{{inlineValue}}
        </textarea>
        <div class="buttons">
          <i [class.disabled]="!form.checkValidity()" class="sm al-ico-success inline-edit-approve pointer" (click)="stopProp($event)" (mousedown)="form.checkValidity() && inlineSaved()"></i>
          <i class="sm al-ico-dialog-x inline-edit-close pointer" [id]="CANCEL_BUTTON" (click)="stopProp($event)"
             (mousedown)="cancelClicked()"></i>
        </div>
      </form>
    </div>
</div>
