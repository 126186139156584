<div class="circles" *ngIf="!isStagger; else staggerContainer">
  <div *ngFor="let circle of data | slice : 0 : (limit || data.length); let index=index; trackBy: trackBy"
       class="circle"
       [class.empty]="!circle.name"
       [class.long]="circle.initials?.length > 4"
       [style.left.px]="-6 * index"
       [smTooltip]="circle.name" matTooltipPosition="above"
  >{{circle.name ? circle.initials || (circle.name | initials) : ''}}</div>
  <div class="circle rest"
       *ngIf="limit && data.length > limit"
       [style.left.px]="-6 * (limit)">+{{data.length - limit}}</div>
</div>
<ng-template #staggerContainer>
  <div class="circles" >
    <div *ngFor="let circle of staggerArray; let index = index; trackBy: trackBy"
         class="circle stagger"
         [style.left.px]="-24 * index"
         [style.zIndex]="staggerArray.length - index"
    >{{index === 0 ? stagger : ''}}</div>
  </div>
</ng-template>
