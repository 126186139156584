<div class="header">
  <i class="far fa-user xl user-icon"></i>
  {{currentUser?.name}}
</div>
<div class="body">
  <div class="title first">User Preferences</div>
  <div class="section toggles">
    <sm-usage-stats #usage></sm-usage-stats>
    <mat-slide-toggle
      *ngIf="supportReScaling"
      [class.first]="!usage.shown"
      (change)="HidpiChange($event)"
      [checked]="disableHidpi">
      Disable HiDPI browser scale override
      <i class="fas fa-info-circle info ml-3 mr-3"
         smTooltip="ClearML dynamically sets the browser scaling factor for an optimal page layout. Disable for default desktop scale."
         matTooltipPosition="above"
      ></i>
      <span class="reload" [class.highlight]="disableHidpiChanged" (click)="reload($event)">Reload to apply</span>
    </mat-slide-toggle>
    <mat-slide-toggle
      (change)="setNeverShowTipsAgain($event)"
      [checked]="(neverShowTipsAgain$ | async)?.includes(popupId)">
      Don’t show pro tips periodically
    </mat-slide-toggle>

  </div>
  <div class="title">WEB APP CLOUD ACCESS <i
    class="fas fa-info-circle info"
    smTooltip="Local, browser cached, access tokens that facilitate retrieving your cloud stored resources"
    matTooltipPosition="above"
  ></i></div>
  <div class="section">
    <sm-s3-access [S3BucketCredentials]="S3BucketCredentials | async"
                  (S3BucketCredentialsChanged)="onS3BucketCredentialsChanged($event)"></sm-s3-access>
  </div>
  <ng-container *smCheckPermission="'engineer'">
    <div *ngIf="communityServer; else credsTitle" class="title">WORKSPACES</div>
    <ng-template #credsTitle>
      <div class="title">APP CREDENTIALS<i
        class="fas fa-info-circle info"
        smTooltip="Credentials providing API access to this workspace"
        matTooltipPosition="above"
      ></i></div>
    </ng-template>
    <div *ngIf="communityServer; else credsSection" class="section mb-4">
      <mat-expansion-panel
        *ngFor="let workspace of workspaces; trackBy: trackByWorkspace"
        togglePosition="before"
        [expanded]="panelState[workspace?.id]"
        (expandedChange)="panelToggle($event, workspace?.id)"
        class="dark-theme mat-elevation-z0"
        [class.expanded]="panelState[workspace?.id]"
      >
        <mat-expansion-panel-header class="dark" expandedHeight="48px" collapsedHeight="48px">
          <mat-panel-title>
            <div class="space-header">
              <span class="space-header-name ellipsis">{{workspace?.name}}</span>
              <span class="active" *ngIf="workspace?.id === activeWorkspace.id">
          <i class="mr-2 al-icon al-ico-currently-active sm"></i>Currently Active
        </span>
              <button (click)="switchWorkspace($event, workspace)" *ngIf="workspace?.id !== activeWorkspace.id" class="btn button-outline-dark">SWITCH TO WORKSPACE</button>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="panel-content">
          <div class="space-content">
            <div class="credentials">
              <div class="space-title"><i class="al-icon al-ico-access-key shield"></i>App Credentials<i
                class="fas fa-info-circle info"
                smTooltip="Credentials providing API access to this workspace"
                matTooltipPosition="above"
              ></i></div>
              <sm-admin-credential-table [credentials]="(credentials$ | async)[workspace?.id]"
                                         (credentialRevoked)="onCredentialRevoked($event, workspace)">
              </sm-admin-credential-table>
              <span
                class="add-button d-flex align-items-center pointer"
                [class.disabled]="creatingCredentials"
                (click)="createCredential(workspace)"
              >
                <i class="al-icon sm al-ico-plus mr-1"></i> Create new credentials
              </span>
            </div>
            <div *ngIf="communityServer" class="members">
              <div class="space-title"><i class="al-icon al-ico-users mr-2"></i>Members</div>
              <div class="d-flex align-items-center flex-wrap">
                <div class="util-text">Workspace has {{workspace?.allocated}} of {{workspace?.allowed}} users</div>
                <sm-circles-in-row [data]="workspace?.users"></sm-circles-in-row>
              </div>
              <ng-container *ngIf="workspace?.id === currentUser.company.id; else leave">
                <button class="btn button-outline-dark mt-4"
                        [disabled]="workspace?.allocated >= workspace?.allowed"
                        (click)="openInviteModal()">INVITE USERS
                </button>
                <div *ngIf="workspace?.allocated >= workspace?.allowed" class="workspace-full">
                  <i class="al-icon al-ico-alert sm mr-2"></i>Workspace has the maximum number of members
                </div>
              </ng-container>
              <ng-template #leave>
                <button (click)="openLeaveModal(workspace)" class="btn button-outline-dark mt-4">LEAVE WORKSPACE</button>
              </ng-template>
            </div>
          </div>
        </div>
      </mat-expansion-panel>


    </div>
    <ng-template #credsSection>
      <div class="section mb-4" *ngFor="let cred of credentials$ | async | keyvalue">
        <sm-admin-credential-table [credentials]="cred?.value"
                                   (credentialRevoked)="onCredentialRevoked($event, workspaces[0])">
        </sm-admin-credential-table>
        <span
          class="add-button d-flex align-items-center pointer"
          [class.disabled]="creatingCredentials"
          (click)="createCredential(workspaces[0])"
        ><i class="al-icon sm al-ico-plus mr-1"></i> Create new credentials
        </span>
      </div>
    </ng-template>
  </ng-container>
</div>
<footer>
  <ng-container *ngIf="communityServer">
    <button *ngIf="!showDeleteAccount; else notice" (click)="deleteAccount()" class="btn button-outline-dark">DELETE ACCOUNT</button>
    <ng-template #notice>
      <span class="notice">Please <a href="https://www.clear.ml/contact-us" target="_blank">contact us</a> to remove your account from the service.</span>
    </ng-template>
  </ng-container>
  <div class="version float-right">
    <span>Version: </span>
    <span>{{version}}</span>
    <ng-container *ngIf="(serverVersions$ | async) as serverVersions">
      <span> • {{serverVersions?.server}}</span>
      <span> • {{serverVersions?.api}}</span>
    </ng-container>
  </div>
</footer>
