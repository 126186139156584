<button smTooltip="{{tooltipText}}"
        [matTooltipPosition]="'above'"
        type="button"
        ngxClipboard
        smClickStopPropagation
        class="btn btn-primary"
        [class]="theme"
        [class.hideBackground]="hideBackground"
        [cbContent]="clipboardText"
        [disabled]="disabled"
        (cbOnSuccess)="copyToClipboard()"
>
  <i class="fa" [class]="copied ? 'fa-check' : copyIcon || 'fa-clipboard'" [class.mr-2]="label"></i>
  <span>{{label}}</span>
</button>
